@use 'styles/variables';

.settings-btn {
    background: none;
    color: $darkGrey;
    @extend %clear-button;
    cursor: pointer;
    outline: none !important;
}

.card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: $white;
    line-height: 1;
    padding: 10px 10px 10px 20px;
    border-bottom: 1px solid $mediumGrey;

    .card-title {
        margin-bottom: 5px;
    }

    @include media-breakpoint-down(sm) {
        padding: 10px;
    }
}

.card-title {
    margin-bottom: 4px;
}

.card-body {
    padding: 27px 32px 32px 33px;
    box-shadow: 2px 2px 15px 2px rgb(0 0 0 / 10%);
    border-radius: $border-radius;

    .without-shadow {
        box-shadow: none;
    }

    .card-table-wrap {
        max-height: 200px;
        overflow-y: auto;
    }

    .data-row {
        display: flex;
        margin-top: 35px;

        .label {
            margin-right: 80px;
            font-weight: 400;
        }

        .value {
            color: $mediumGrey;
            font-weight: 500;
        }
    }
}

.chart-wrap {
    padding: 30px;
    height: 350px;
    position: relative;

    @include media-breakpoint-down(sm) {
        height: 250px;
        padding: 10px 5px;
    }
}

.card {
    border: 1px solid #e5e5e5;
    margin-bottom: 1rem;
    border-radius: $border-radius;

    &.equal {
        margin-bottom: 0;
        height: 100%;
    }

    & > .preloader {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background: rgba($white, 0.8);
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 10;

        i {
            font-size: 70px;
        }
    }

    .card__header {
        label {
            font-size: 18px;
            color: $ddFontPrimary;
        }
    }

    div {
        .row {
            &.adjust-half-row {
                margin: -12px -15px;
            }

            .column {
                padding-right: 30px;
            }
        }
    }

    &.active {
        background-color: $ddCoreYellow;
    }
}

.col-equal {
    padding-bottom: 30px;
}

@media (max-width: 575px) {
    .head_elements {
        .card {
            margin-bottom: 0;

            .card-body {
                padding: 0;
                box-shadow: none;
                border-radius: 0;
                border: none;
            }
        }
    }

    .card {
        .card-body {
            padding: 22px 22px 22px 23px;

            .card-title {
                padding-bottom: 10px;
                margin-bottom: 10px;
                border-bottom: solid 1px $border-headings;
            }
        }
    }
}

.card-shadow {
    border: none;
    box-shadow: variables.$card-shadow;
}

.card-hover:hover:not(.active) {
    background-color: $ddContextYellow100;
}
