.mat-stepper-horizontal {
    .mat-horizontal-stepper-header-container {
        @include media-breakpoint-down(sm) {
            display: none;
        }

        .mat-step-header {
            .mat-step-icon {
                font-weight: 500;
                width: 26px;
                height: 26px;
                background-color: $ddFontSecondary;
                color: $white;

                &.mat-step-icon-selected {
                    background-color: $ddCoreYellow;
                    color: $ddFontPrimary;
                }
            }
        }
    }

    .mat-horizontal-content-container {
        padding: 0;
    }
}
