mat-paginator {
    .mat-mdc-paginator-range-actions {
        button .mat-mdc-paginator-icon {
            vertical-align: top;
        }

        @include media-breakpoint-down(sm) {
            .mat-mdc-paginator-range-label {
                margin: 8px !important;
            }
            button {
                padding: 8px !important;

                .mat-mdc-paginator-icon {
                    vertical-align: baseline;
                }
            }
        }
    }
}
