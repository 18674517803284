.mat-mdc-radio-button {
    .mat-mdc-radio-checked .mdc-radio__background::before {
        background: none;
    }

    .mdc-radio .mdc-radio__native-control:enabled {
        &:checked + .mdc-radio__background .mdc-radio__outer-circle {
            border-color: $ddCoreBlack !important;
        }

        &:not(:checked) + .mdc-radio__background .mdc-radio__outer-circle {
            border-color: $ddCoreBlack !important;
        }

        & + .mdc-radio__background .mdc-radio__inner-circle {
            border-color: $ddCoreBlack !important;
        }
    }
}
