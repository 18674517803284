@use 'sass:math';
@use 'styles/variables';

@each $color, $value in $theme-colors {
    .btn-outline-#{$color} {
        --bs-btn-color: #{variables.$ddFontPrimary};

        &.disabled {
            --bs-btn-disabled-color: #{variables.$ddFontPrimary};
        }
    }
}

.card {
    border-radius: 6px;

    &-transparent {
        background-color: rgb(229, 229, 229, 0.6);
    }

    &-dark {
        background-color: rgb(14, 14, 14, 0.6);
        border: #0e0e0e;
    }

    &-gray {
        background-color: rgba(167, 167, 167, 0.6);
    }
}

h1,
h2,
h3,
h4,
h5,
h6 {
    hyphens: auto;

    i {
        vertical-align: middle;
    }
}

b,
strong {
    font-weight: $font-weight-bold;
}

.btn {
    min-height: 24px;
}

a:not(.btn),
.btn-link {
    color: variables.$ddFontPrimary;
    text-decoration: none;
    cursor: pointer;
    font-size: 14px;
    font-weight: 300;
    line-height: 160%;

    &:hover,
    &:focus-visible {
        color: variables.$ddFontPrimary !important;
    }
}

button:not(.btn) {
    border: none;
}

.text-with-document-link-container {
    a {
        color: variables.$ddFontPrimary;
        text-decoration: underline;
        cursor: pointer;
        font-size: variables.$default-font-size;
        font-weight: 300;
        line-height: 160%;
    }
}

.has-dark-bg {
    .btn-link {
        color: variables.$ddFontPrimaryContrast;

        &:hover {
            color: variables.$ddCoreYellow !important;
        }
    }
}

mat-sidenav-container,
.modal,
.layout-unauthorized,
.mat-mdc-dialog-container {
    .btn {
        &.btn-primary {
            --bs-btn-disabled-bg: #d8d8d8;
            --bs-btn-disabled-border-color: #d8d8d8;
            --bs-btn-disabled-opacity: 1;
        }

        &-modal {
            &:first-child {
                margin-left: auto;
                margin-right: 10px;
            }

            &:last-child {
                margin-right: auto;
                margin-left: 10px;
            }
        }

        &.btn-xs {
            font-size: 14px;
            font-weight: 300;
            min-width: 40px;
            padding: 0.4em 0.6em;

            span {
                vertical-align: middle;
            }

            &.btn-ico {
                i {
                    margin: 0;
                    font-size: 14px;
                    text-decoration: none;
                }
            }
        }

        &-link {
            display: inline-flex;
            align-items: center;

            > * + i {
                margin: 0 0 0 5px;
            }
        }
    }
}

.dropdown-menu {
    box-shadow: $box-shadow;
    border: none;
}

label {
    color: variables.$darkGrey;
    font-size: 15px;
    font-weight: 400;
}

dl {
    margin-bottom: 0;
    display: grid;
    grid-template-columns: auto auto;
    gap: 0.5rem;
    grid-auto-rows: max-content;

    dt,
    dd {
        margin-bottom: 0;
    }
    &.justify-items-end {
        justify-items: end;
    }
    &.justify-values-end {
        dd {
            justify-self: end;
        }
    }
}

//Modals
%modal-box {
    padding: 12px 20px;

    @include media-breakpoint-up(sm) {
        padding: 15px 40px;
    }
}

bs-modal-backdrop {
    z-index: 1048 !important;
}

modal-container {
    z-index: 1049 !important;
}

.modal-content {
    padding-top: 0;
    padding-bottom: 0;
    position: relative;

    @include media-breakpoint-down(md) {
        padding: 20px;
    }
}

.modal-close,
.dialog-close {
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 1051;
}

.modal-header {
    @extend %modal-box;
    border-bottom: 0;
    padding: 15px 20px;
}

.modal-body {
    @extend %modal-box;
    padding: 45px 45px 20px 45px !important;
    padding-top: 10px !important;

    .text-holder {
        margin: 2em 0 2em 0;
    }

    .title {
        font-style: normal;
        font-weight: 300;
        font-size: 24px;
        line-height: 42px;
        text-align: center;
        color: variables.$darkGrey;
    }

    img {
        -webkit-filter: grayscale(100%);
        -moz-filter: grayscale(100%);
        -ms-filter: grayscale(100%);
        -o-filter: grayscale(100%);
        filter: gray;
        opacity: 0.5;
        margin-bottom: 40px;
        margin-top: 20px;
    }

    .subtitle {
        font-style: normal;
        font-weight: 300;
        font-size: 13px;
        line-height: 20px;
        text-align: center;
        color: variables.$darkGrey;
        margin-bottom: 20px;
    }

    .upload-block {
        text-decoration: none;
        width: 100%;
    }
}

.modal-footer {
    @extend %modal-box;
    border-top: none;
    padding: 10px 0px 45px 45px !important;
}

.modal-title {
    margin-right: 30px;
}

@media (max-width: 575px) {
    .modal-footer,
    .modal-body {
        padding: 0 !important;
    }
}

input[type='number'] {
    -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

.modal-width-800 {
    max-width: 800px;
}

.modal-title-center {
    .modal-header {
        justify-content: center;
    }
}

.modal-footer-right {
    .modal-footer {
        justify-content: flex-end !important;
    }
}

.pointer {
    cursor: pointer;
}

legend {
    float: unset;
}

// Adds margins to <i> if it has any following or preceding element
button,
a {
    i:has(+ *) {
        margin-right: $spacer * 0.5;
    }

    * + i {
        margin-left: $spacer * 0.5;
    }
}

.flex-even {
    flex: 1 1 0;
}

.alert {
    color: $ddFontPrimary;
}
