// temporary fix for padding; implement dialogs following the guide https://material.angular.io/components/dialog
.mat-mdc-dialog-container {
    --mdc-dialog-supporting-text-color: variables.$ddFontSecondary;

    .mat-mdc-dialog-surface {
        padding: 24px;
    }
}

.mat-mdc-dialog-surface--no-padding {
    .mat-mdc-dialog-surface {
        padding: 0;

        .mat-mdc-dialog-content {
            padding: 24px;
        }
    }
}

.cdk-overlay-container {
    z-index: 1050;

    .cdk-overlay-pane {
        &.mobile-full-width {
            @include media-breakpoint-only(xs) {
                width: 100%;
            }
        }
    }
}

.dialog-close-area {
    display: flex;
    justify-content: flex-end;
}
