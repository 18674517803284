@use 'sass:math';

/* Clear button */
%clear-button {
    outline: none;
    border: none;
    padding: 0;
    margin: 0;
    width: auto;
}

@mixin circle($size) {
    width: $size;
    height: $size;
    border-radius: 50%;
}

@function em($pixels, $context: $default-font-size) {
    @if (unitless($pixels)) {
        $pixels: $pixels * 1px;
    }

    @if (unitless($context)) {
        $context: $context * 1px;
    }
    @return math.div($pixels, $context) * 1em;
}

@mixin form-label-size($size, $height, $font-size, $line-height: 1) {
    .form-label#{$size} {
        line-height: $line-height;
        font-size: $font-size;
    }
}

%flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

%full-width {
    width: 100%;
    height: 100%;
}

@mixin even() {
    &:nth-child(even) {
        @content;
    }
}

@mixin transition($args...) {
    -webkit-transition: $args;
    -moz-transition: $args;
    -ms-transition: $args;
    -o-transition: $args;
    transition: $args;
}
