@import 'assets/icomoon/style.css';

[class^='icon-'] {
    font-size: 2.5rem;
    color: $ddFontPrimary;

    &.icon-xl {
        font-size: 3rem;
    }

    &.icon-primary {
        color: $ddCoreYellow !important;
    }
}
