@use 'styles/variables';

.head-content {
    padding: 20px 0px 15px;
    background-color: $body-bg;
    max-width: 100%;
    @include media-breakpoint-up(xxl) {
        padding: 20px 30px 15px;
    }
    .title {
        margin-bottom: 10px;
        &:first-letter {
            text-transform: uppercase;
        }
    }
    .subtitle > div:not(:empty) {
        line-height: 1.3;
    }
    .subtitle-value {
        color: variables.$ddFontSecondary;
    }
}

.btn-wrap {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: stretch;
    margin: 0;
    @include media-breakpoint-up(sm) {
        margin: 0;
        width: auto;
        flex-direction: row;
        align-items: flex-end;
    }
    .btn-wrap-item {
        margin-top: 0px;
        @include media-breakpoint-up(sm) {
            margin-right: 20px;
        }
        &:only-child,
        &:last-child {
            margin-right: 0px;
            margin-left: auto;
        }
    }
    + .btn-wrap {
        margin-top: 5px;
    }
}

.back-link {
    color: $mediumGrey;
    margin-right: 16px;
    margin-top: 6px;
    @include media-breakpoint-up(sm) {
        margin-right: 26px;
    }
    &:hover {
        color: variables.$ddCoreYellow;
    }
    i {
        font-size: 28px;
    }
}

@media (max-width: 576px) {
    .head_elements {
        mrc-head-content {
            .head-content {
                padding-top: 0;
                .col-left {
                    display: none !important;
                }
            }
        }
    }
    .head-content {
        padding: 10px 30px 30px;
        .title {
            font-weight: 600;
            font-size: 1.9em;
        }
        .subtitle,
        .btn-wrap {
            .btn {
                display: block;
                width: 100%;
                height: auto;
            }
        }
    }
}
