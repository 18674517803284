.mat-mdc-chip {
    background-color: $ddCoreYellow !important;

    &.mat-warn {
        background-color: $ddCoreRed !important;

        .mat-mdc-chip-action-label {
            color: $white !important;
        }
    }

    &.mat-black {
        background-color: $ddCoreBlack !important;

        .mat-mdc-chip-action-label {
            color: $white !important;
        }
    }
}
