@media print {
    html {
        font-size: 8.5pt;
    }
    .mat-sidenav {
        display: none !important;
    }
    .mat-sidenav-content {
        margin-left: 0 !important;
    }
}
