@use 'sass:math';

.wrapper {
    overflow-y: auto !important;
    -webkit-overflow-scrolling: touch !important;
}

//Container
$container-offset-x: 15px;

//Bootstrap
$grid-breakpoints: (
    xs: 0,
    xsm: 320px,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1680px,
);

// Top Navbar
$navbar-height-mobile: 50px;
$navbar-height: 57px;

$border-color: rgba(0, 0, 0, 0.125);
$input-padding-x: 0.375rem;
$input-padding-y: 0.5rem;
$modal-offset-x: 40px;
$modal-md: 570px;
$modal-content-border-radius: 5px;
$modal-header-padding: 15px $modal-offset-x;
$modal-inner-padding: 15px $modal-offset-x;
$modal-dialog-margin: $container-offset-x;

$border-radius: 8px;

//Path
$path-img: '/assets/images/';

//Fonts
$lexend: Lexend, sans-serif;
//Fonts Bootstrap
$font-family-sans-serif: $lexend;
$font-family-base: $lexend;
$input-btn-font-family: $lexend;

//Colors
$black: #000;
$white: #fff;
$darkGrey: #4f5862;
$mediumGrey: #e5e5e5;
$mediumGreyNew: #78797a;

/**
 * Theme colors are defined here and in file core/constants/constants.ts
 */
// Core Palette
$ddCoreBlack: #060607;
$ddCoreYellow: #ffcd00;
$ddCoreRed: #c83c3c;
// End - Core Palette

// Base Colors
$ddFontPrimary: $ddCoreBlack; // Headers, Input text, Button Label
$ddFontPrimaryContrast: $white;
$ddFontSecondary: #464d59; // Menu items, Sub headers, Text
$ddFontDisabled: #a9b1bc; // Text, Input Text
$ddBoxes: #f2f2f2; // Input fields, Icon Boxes
$ddBackground: #f8f9fa; // Page Background
$ddCards: $white; // Cards
// End - Base Colors

// Contextual Colors - For Warnings, Errors, Success Notifications
$ddContextYellow: $ddCoreYellow;
$ddContextYellow500: #ffba00;
$ddContextYellow400: $ddContextYellow;
$ddContextYellow300: #ffde5c;
$ddContextYellow200: #ffeb99;
$ddContextYellow100: #fff7d6;

// Mobility Hub
$ddSponsored: #ffcc0034;
$ddSponsoredHover: #ffcc00;
$ddCardBackground: #f2f2f2;
$ddCardHoverShadow: 0px 0px 25px #06060740;

$ddContextRed: $ddCoreRed;
$ddContextRed500: #ab0c10;
$ddContextRed400: $ddContextRed;
$ddContextRed300: #f34f53;
$ddContextRed200: #f7888b;
$ddContextRed100: #fbc1c3;

$ddContextOrange: #f97a05;
$ddContextOrange500: #f54a01;
$ddContextOrange400: $ddContextOrange;
$ddContextOrange300: #fb9a41;
$ddContextOrange200: #fcba7d;
$ddContextOrange100: #fedfc3;

$ddContextGreen: #2e8558;
$ddContextGreen500: #1f5c42;
$ddContextGreen400: $ddContextGreen;
$ddContextGreen300: #3db876;
$ddContextGreen200: #85d6a7;
$ddContextGreen100: #d9f2e5;

$ddContextBlue: #0063bd;
$ddContextBlue500: #004f98;
$ddContextBlue400: $ddContextBlue;
$ddContextBlue300: #0081fa;
$ddContextBlue200: #75bcff;
$ddContextBlue100: #cce3f8;
// End - Contextual Colors

/**
 * New Colors (legacy)
 */
$lighterBlue: #f2f5f7;
$lightGrey: #eceff0;
$background-grey: #eeeeee;
$main-content-background: $ddBackground;

/**
 * Bootstrap Theme Variables
 */
$primary: $ddCoreYellow;
$secondary: $mediumGrey;
$success: $ddContextGreen;
$info: $ddContextBlue;
$warning: $ddCoreYellow;
$danger: $ddContextRed;
$light: $ddBackground;
$dark: $darkGrey;

$theme-colors: (
    'primary': $primary,
    'secondary': $secondary,
    'success': $success,
    'info': $info,
    'warning': $warning,
    'danger': $danger,
    'light': $light,
    'dark': $dark,
);

/**
  * Bootstrap Color Variables
  */
$blue: $ddContextBlue;
$indigo: #6610f2;
$purple: #6f42c1;
$pink: #d63384;
$red: $ddContextRed;
$orange: $ddContextOrange;
$yellow: $ddContextYellow;
$green: $ddContextGreen;
$teal: #20c997;
$cyan: #0dcaf0;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;

$colors: (
    'blue': $blue,
    'indigo': $indigo,
    'purple': $purple,
    'pink': $pink,
    'red': $red,
    'orange': $orange,
    'yellow': $yellow,
    'green': $green,
    'teal': $teal,
    'cyan': $cyan,
    'black': $black,
    'white': $white,
    'gray': $gray-600,
    'gray-dark': $gray-800,
);

/**
  * Bootstrap Contrast
  */
$min-contrast-ratio: 3;

/**
  * Bootstrap Buttons
  */
$input-btn-font-size: 14px;
$input-btn-font-weight: 500;

.btn-link {
    text-decoration: underline;
}

$border-headings: $background-grey;

//Default settings
$default-font-size: 14px;
$default-line-height: 1.5;
$body-bg: $white;

$default-animation: 0.3s ease;

$box-shadow: 0 3px 7px 0 #0000001f;
$card-shadow: 2px 2px 15px 2px #0000001a;

//Form elements
$form-group-offset-y: 8pt;

$form-control-height: 45px;
$form-control-size: 14px;
$form-control-line-height: 2;
$form-control-offset-x: 15px;

$form-label-size: 16px;
$form-label-line-height: 1;

$form-control-sm-height: 35px;
$form-label-sm-size: 15px;
$form-label-sm-line-height: 1;

//switcher
$switcher-track-width: 64px;
$switcher-track-height: math.div($switcher-track-width, 2);
$switcher-track-radius: math.div($switcher-track-height, 2);
$switcher-track-bg: $ddFontPrimary;
$switcher-track-bg-active: $ddCoreYellow;
$switcher-track-offset: 3px;

$swither-toggle-width: math.div($switcher-track-width, 2) - 2 *
    $switcher-track-offset;
$swither-toggle-height: $switcher-track-height - 2 * $switcher-track-offset;
$swither-toggle-bg: $background-grey;

//sort
$sort-width: 7.7px;
$sort-height: 4px;
$sort-color: $ddFontPrimary;
$sort-color-active: $darkGrey;

//table
$table-border-color: #dadcdf;
$table-cell-offset-x: 20px;
$table-cell-offset-y: 15px;
$table-row-color-hover: $ddBoxes;
$table-row-even-bg: $ddBackground;

//pagination
$pagination-cell-size: 38px;
$pagination-cell-radius: 3px;
$pagination-cell-color: $ddFontPrimary;
$pagination-cell-color-active: $darkGrey;

//Content
$content-offset-x: 30px;
$content-offset-x-sm: 15px;

$head-content-offset-x: $content-offset-x;
$head-content-offset-x-sm: $content-offset-x-sm;
$head-content-offset-y: 30px;
$head-content-offset-y-sm: 30px;
$head-content-bg: $white;

$main-content-offset-x: $content-offset-x;
$main-content-offset-x-sm: $content-offset-x-sm;
$main-content-offset-y: 25px;
$main-content-offset-y-sm: 30px;

$shop-table-white-padding-x: 32px;
$shop-table-white-padding-x-sm: 16px;
$shop-table-white-padding-y: 16px;
$shop-table-white-padding-y-sm: 8px;

$publicFooterHeight: 54px;
$mobile-navigation-height: 60px;
