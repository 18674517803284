.mat-mdc-slider {
    --mdc-slider-active-track-height: 2px;
    --mdc-slider-inactive-track-height: 2px;
    --mdc-slider-handle-width: 16px;
    --mdc-slider-handle-height: 16px;

    &.mat-primary {
        --mdc-slider-handle-color: #687078;
        --mdc-slider-focus-handle-color: #687078;
        --mdc-slider-hover-handle-color: #687078;
        --mdc-slider-active-track-color: #687078;
        --mdc-slider-inactive-track-color: #687078;
        --mdc-slider-with-tick-marks-active-container-color: #000;
        --mdc-slider-with-tick-marks-inactive-container-color: #687078;
    }

    & .mat-ripple .mat-mdc-slider-focus-ripple,
    & .mat-ripple .mat-mdc-slider-active-ripple {
        outline: 2px solid $ddContextBlue;
        -moz-outline-radius: 6px;
        box-shadow: none;
        outline-offset: -2px;
    }
}
