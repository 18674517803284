/**
 * Focus ring
 */
@mixin dd-outline {
    outline: 2px solid $ddContextBlue;
    -moz-outline-radius: 6px;
    box-shadow: none;
}

@mixin dd-outline-dark-bg {
    outline: 2px solid $ddCoreYellow !important;
    -moz-outline-radius: 6px;
    box-shadow: none;
}

@mixin dd-outline-offset {
    outline-offset: 2px;
}

@mixin dd-outline-offset-negative {
    outline-offset: -2px;
}

// Focus direct on Element
*[tabindex='0'],
button,
a,
input,
textarea {
    @include dd-outline-offset;

    &:focus,
    &:focus:focus-visible {
        @include dd-outline;
    }

    &.has-dark-bg {
        &:focus,
        &:focus:focus-visible {
            @include dd-outline-dark-bg;
        }
    }
}

// Exception for direct focus
//  In case the click event is on the wrapper not direkt on the visible ui element
dd-status-pill[tabindex='0']:focus {
    outline: none;

    .status-pill {
        @include dd-outline;
    }
}

// Focus indirect on Element
// for mui-components
.ng-select.ng-select-focused,
.ng-select.ng-select-single.ng-select-focused,
.file-upload input:focus ~ .focus-ring,
.ng-option-marked {
    @include dd-outline-offset;
    @include dd-outline;
}

// Negative outline offset for elements with a wrapper that has overflow hidden
.ng-option-marked,
.mat-mdc-option-active,
.mat-mdc-menu-content > *,
.ng-option {
    &:focus,
    &:focus:focus-visible {
        @include dd-outline-offset-negative;
    }
}

.ng-option-marked {
    @include dd-outline-offset-negative;
}

mat-radio-button {
    outline: none;

    .mdc-form-field {
        outline: none;
    }
}

// Specific behaviour adjustments
mat-radio-button input:focus {
    ~ .mdc-radio__background {
        border-radius: 50%;
        @include dd-outline-offset;
        @include dd-outline;
    }

    ~ .mdc-checkbox__ripple,
    mat-ripple,
    &.mdc-radio--selected ~ .mdc-radio__ripple {
        background: none !important;
    }
}

mat-checkbox input:focus {
    ~ .mdc-checkbox__background {
        @include dd-outline-offset;
        @include dd-outline;
    }

    ~ .mdc-checkbox__ripple,
    &.mdc-checkbox--selected ~ .mdc-checkbox__ripple {
        background: none !important;
    }
}

.has-dark-bg {
    ::ng-deep {
        &:focus,
        &:focus-visible {
            outline: auto !important;
        }
    }

    .btn-link {
        color: $ddFontPrimaryContrast;

        &:hover {
            color: $ddCoreYellow;
        }
    }
}
