@use 'sass:math';
@use 'styles/variables';

.form-control-holder {
    position: relative;

    &.invalid-textarea {
        border: 1px solid variables.$danger;
    }
}

.input-group .input-group-text {
    z-index: 4;
    padding: variables.$input-padding-y variables.$input-padding-x;
}

.input-group > .form-control:not(:last-child) {
    border-radius: $border-radius;
}

@include form-label-size(
    '',
    variables.$form-control-height,
    variables.$form-label-size,
    variables.$form-label-line-height
);
@include form-label-size(
    '-sm',
    variables.$form-control-sm-height,
    variables.$form-label-sm-size,
    variables.$form-label-sm-line-height
);

.form-control-bg,
.form-control-bg[readonly],
.form-control-bg[readonly]:focus {
    color: variables.$darkGrey;
    border: 0.8pt solid #e5e5e5;
    background-color: #fff;
    border-radius: 2px;
}

.form-control-bg:focus {
    border: 0.6px solid variables.$darkGrey;
    background-color: #fff;
}

.form-control {
    $offset-y: math.div(
        (
            variables.$form-control-height - variables.$form-control-size *
                variables.$form-control-line-height
        ),
        2
    );

    padding: $offset-y ($form-control-offset-x + 15px) $offset-y
        $form-control-offset-x;

    background-position: right 12px center;
    background-repeat: no-repeat;
    background-size: 24px 24px;
    background-color: variables.$white;

    height: variables.$form-control-height;
    min-height: variables.$form-control-height;
    line-height: variables.$form-control-line-height;
    font-size: variables.$form-control-size;
    color: variables.$ddFontSecondary;
    border: 1pt solid variables.$ddFontSecondary;
    border-radius: 3px;
    box-shadow: none !important;

    &.ng-dirty:not(.novalidate) {
        &.ng-invalid {
            border-color: variables.$danger;
            background-image: url($path-img + 'ico-validation-error.svg');
            background-position: right 15px center;
            background-size: 12px;
        }

        &.ng-valid {
            border-color: variables.$success;
            background-image: url($path-img + 'ico-validation-success.svg');
            background-position: right 15px center;
            background-size: 12px;
        }
    }

    &.locked {
        background-repeat: no-repeat;
        background-size: 15px;
        background-position: right 15px center;
        background-image: url($path-img + 'lock.svg');
    }

    &:disabled {
        border-right-color: #e9ecef;
    }

    &:focus {
        color: variables.$ddFontPrimary;
        border-color: variables.$ddFontSecondary;
    }
}

.form-control-height {
    height: variables.$form-control-height;
}

.form-control::placeholder,
input::placeholder {
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    display: flex;
    align-items: center;
    text-indent: 10px;
    color: variables.$ddFontSecondary;
}

input:disabled.form-control,
textarea:disabled.form-control {
    background-color: $ddBackground;
    border: none;
}

.form-group {
    margin: $form-group-offset-y 0;

    i.info {
        color: variables.$darkGrey;
    }

    .is-required:after {
        content: '*';
        color: variables.$ddFontPrimary;
        margin-left: 2px;
    }

    label,
    .label {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        color: variables.$ddFontPrimary;
    }

    mrc-head-content & {
        margin: 0;
    }

    &.form-group-color {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}

textarea.form-control {
    min-height: 200px;
    padding: 12px 15px;
}

.upload-block {
    background-color: variables.$ddBackground;
    border: dashed 1px variables.$ddFontPrimary;
    border-radius: variables.$border-radius;
    text-align: center;
    display: block;
    padding: 34px 0 27px;
    font-size: em(14);
    text-decoration: underline;

    i {
        font-size: em(38);
    }
}

.form-group-inline {
    display: flex;
    align-items: center;
    justify-content: space-between;

    label {
        margin: 0 20px 0 0;
    }

    .form-control-holder {
        max-width: 120px;
    }
}

.form-group-btn {
    margin: 30px 0;
}
