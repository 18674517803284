@use 'styles/variables';

.mat-mdc-tab-header {
    border: none;
    box-shadow: variables.$card-shadow;
    border-radius: variables.$border-radius;
    overflow: hidden;

    .mat-mdc-tab-labels {
        display: table;
        width: 100%;
        background: variables.$white;

        .mat-mdc-tab {
            border-top: 1px solid variables.$mediumGrey !important;
            border-bottom: 1px solid variables.$mediumGrey !important;
            border-left: 1px solid variables.$mediumGrey !important;
            border-right: none !important;
            height: 34px;
            display: table-cell;
            padding: 7px 0;

            &:first-child {
                border-radius: variables.$border-radius 0 0
                    variables.$border-radius;
            }

            &:last-child {
                border-right: 1px solid variables.$mediumGrey !important;
                border-radius: 0 variables.$border-radius
                    variables.$border-radius 0;
            }

            .mdc-tab__text-label {
                text-transform: none;
                font-size: 0.8rem;
            }

            .mdc-tab-indicator {
                display: none;
            }

            &:hover {
                background-color: variables.$lighterBlue;
                color: variables.$darkGrey;
            }

            &.mdc-tab--active {
                background-color: variables.$darkGrey !important;
                opacity: 1;
                border: 1px solid variables.$darkGrey !important;

                .mdc-tab__text-label {
                    color: variables.$white;
                }

                i {
                    color: variables.$white;
                }

                &:hover {
                    background-color: variables.$darkGrey !important;
                    border: 1px solid variables.$darkGrey !important;
                }
            }
        }
    }
}

.mat-mdc-tab-nav-bar,
.mat-mdc-tab-header {
    border: none;
}
