@font-face {
    font-family: 'Lexend';
    src: url('../assets/fonts/Lexend-Thin.ttf') format('truetype');
    font-weight: 100;
}
@font-face {
    font-family: 'Lexend';
    src: url('../assets/fonts/Lexend-ExtraLight.ttf') format('truetype');
    font-weight: 200;
}
@font-face {
    font-family: 'Lexend';
    src: url('../assets/fonts/Lexend-Light.ttf') format('truetype');
    font-weight: 300;
}
@font-face {
    font-family: 'Lexend';
    src: url('../assets/fonts/Lexend-Regular.ttf') format('truetype');
    font-weight: 400;
}
@font-face {
    font-family: 'Lexend';
    src: url('../assets/fonts/Lexend-Medium.ttf') format('truetype');
    font-weight: 500;
}
@font-face {
    font-family: 'Lexend';
    src: url('../assets/fonts/Lexend-SemiBold.ttf') format('truetype');
    font-weight: 600;
}
@font-face {
    font-family: 'Lexend';
    src: url('../assets/fonts/Lexend-Bold.ttf') format('truetype');
    font-weight: 700;
}
@font-face {
    font-family: 'Lexend';
    src: url('../assets/fonts/Lexend-ExtraBold.ttf') format('truetype');
    font-weight: 800;
}
@font-face {
    font-family: 'Lexend';
    src: url('../assets/fonts/Lexend-Black.ttf') format('truetype');
    font-weight: 900;
}

h1 {
    font-size: 36px;
    font-weight: 700;
    line-height: 160%;
    @include media-breakpoint-down(md) {
        font-size: 26px;
    }
}
h2 {
    font-size: 26px;
    font-weight: 700;
    line-height: 160%;
    @include media-breakpoint-down(md) {
        font-size: 20px;
    }
}
h3 {
    font-size: 18px;
    font-weight: 600;
    line-height: 160%;
}
h4 {
    font-size: 16px;
    font-weight: 600;
    line-height: 160%;
}
h5 {
    font-size: 14px;
    font-weight: 600;
    line-height: 160%;
}
h6 {
    font-size: 14px;
    font-weight: 400;
    line-height: 160%;
}
p,
a,
input {
    font-size: 14px;
    font-weight: 300;
    line-height: 160%;
}
button {
    font-size: 14px;
    font-weight: 500;
    line-height: 160%;
}
th,
td,
li {
    font-size: 12px;
    font-weight: 300;
    line-height: 160%;
}
small {
    font-size: 10px;
    font-weight: 300;
    line-height: 200%;
}
