@use 'styles/variables';

// Overwrite iOS-System-Agend-Stylesheet
input:is([type='button'], [type='submit'], [type='reset']),
input[type='file'],
button {
    &:not(.btn) {
        color: variables.$ddFontPrimary;
    }
}
