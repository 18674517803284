$selected-track-color: #d8d8d8;
$selected-handle-color: #060607;

.mat-mdc-slide-toggle {
    --mdc-switch-selected-focus-state-layer-color: #{$selected-handle-color} !important;
    --mdc-switch-selected-hover-state-layer-color: #{$selected-handle-color} !important;
    --mdc-switch-selected-pressed-state-layer-color: #{$selected-handle-color} !important;

    --mdc-switch-selected-track-color: #{$selected-track-color} !important;
    --mdc-switch-selected-focus-track-color: #{$selected-track-color} !important;
    --mdc-switch-selected-hover-track-color: #{$selected-track-color} !important;
    --mdc-switch-selected-pressed-track-color: #{$selected-track-color} !important;

    --mdc-switch-selected-handle-color: #{$selected-handle-color} !important;
    --mdc-switch-selected-focus-handle-color: #{$selected-handle-color} !important;
    --mdc-switch-selected-hover-handle-color: #{$selected-handle-color} !important;
    --mdc-switch-selected-pressed-handle-color: #{$selected-handle-color} !important;

    --mdc-switch-track-height: 28px !important;
    --mdc-switch-track-shape: 14px !important;
    --mdc-switch-track-width: 48px !important;

    --mat-switch-unselected-handle-size: 20px !important;
    --mat-switch-selected-handle-size: 20px !important;
    --mat-switch-pressed-handle-size: 20px !important;
    --mat-switch-with-icon-handle-size: 20px !important;

    --mat-switch-selected-handle-horizontal-margin: 0 -4px !important;
    --mat-switch-selected-with-icon-handle-horizontal-margin: 0 -4px !important;
    --mat-switch-selected-pressed-handle-horizontal-margin: 0 -4px !important;
    --mat-switch-unselected-handle-horizontal-margin: 0 4px !important;
    --mat-switch-unselected-with-icon-handle-horizontal-margin: 0 4px !important;
    --mat-switch-unselected-pressed-handle-horizontal-margin: 0 4px !important;

    --mat-switch-track-outline-width: 1px;
    --mat-switch-track-outline-color: #{$selected-handle-color} !important;
    --mat-switch-selected-track-outline-width: 1px;
    --mat-switch-selected-track-outline-color: #{$selected-handle-color} !important;
    --mat-switch-disabled-unselected-track-outline-width: 1px;
    --mat-switch-disabled-unselected-track-outline-color: #{$selected-handle-color} !important;
}
