@font-face {
    font-family: 'icomoon';
    src: url('fonts/icomoon.eot?ovhsut');
    src:
        url('fonts/icomoon.eot?ovhsut#iefix') format('embedded-opentype'),
        url('fonts/icomoon.ttf?ovhsut') format('truetype'),
        url('fonts/icomoon.woff?ovhsut') format('woff'),
        url('fonts/icomoon.svg?ovhsut#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-bikesde_akku-kapazitat:before {
    content: '\e91b';
}
.icon-bikesde_akku:before {
    content: '\e91c';
}
.icon-bikesde_motor:before {
    content: '\e91d';
}
.icon-lock-premium:before {
    content: '\e900';
}
.icon-lock-standard:before {
    content: '\e901';
}
.icon-bikesde_bidex-ws:before {
    content: '\e902';
}
.icon-bikesde_brake-levers:before {
    content: '\e903';
}
.icon-bikesde_brakes:before {
    content: '\e904';
}
.icon-bikesde_chain:before {
    content: '\e905';
}
.icon-bikesde_crankset:before {
    content: '\e906';
}
.icon-bikesde_derailleur:before {
    content: '\e907';
}
.icon-bikesde_fork:before {
    content: '\e908';
}
.icon-bikesde_front-light:before {
    content: '\e909';
}
.icon-bikesde_grips:before {
    content: '\e90a';
}
.icon-bikesde_handlebar:before {
    content: '\e90b';
}
.icon-bikesde_headset:before {
    content: '\e90c';
}
.icon-bikesde_mudguards:before {
    content: '\e90d';
}
.icon-bikesde_number-of-gears:before {
    content: '\e90e';
}
.icon-bikesde_pedals:before {
    content: '\e90f';
}
.icon-bikesde_rack:before {
    content: '\e910';
}
.icon-bikesde_rims:before {
    content: '\e911';
}
.icon-bikesde_saddle:before {
    content: '\e912';
}
.icon-bikesde_seat-post:before {
    content: '\e913';
}
.icon-bikesde_shift-levers:before {
    content: '\e914';
}
.icon-bikesde_spokes:before {
    content: '\e915';
}
.icon-bikesde_sprockets:before {
    content: '\e916';
}
.icon-bikesde_stand:before {
    content: '\e917';
}
.icon-bikesde_stem:before {
    content: '\e918';
}
.icon-bikesde_tires:before {
    content: '\e919';
}
.icon-bikesde_weight:before {
    content: '\e91a';
}
