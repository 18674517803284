@use 'sass:math';
@use 'styles/variables';

.mrc-table {
    background: transparent;

    mrc-status-tabs .mat-tab-header {
        border-radius: variables.$border-radius;
        background: #fff;
    }

    .mrc-datatable {
        margin-top: 15px;
        background: #fff;
        border: 1px solid #e5e5e5;
        box-sizing: border-box;
        border-radius: 12px;
    }
}

.ngx-datatable.mrc-datatable {
    box-shadow: 2px 2px 15px 2px rgb(0 0 0 / 10%);

    &.no-shadow {
        box-shadow: none;
    }

    .datatable-body-cell {
        &:hover {
            text-overflow: unset;
            width: auto;
        }

        .datatable-body-cell-label {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-size: 12px;

            &:hover {
                text-overflow: unset;
                width: auto;
                position: absolute;
                background: variables.$ddBoxes;
                z-index: 999;
                padding: 0 15px;
                margin: 0 -15px !important;
            }
        }
    }

    .datatable-body {
        min-height: 3px;

        .datatable-scroll {
            vertical-align: top;

            .datatable-row-wrapper,
            .datatable-row-wrapper .datatable-body-row {
                height: 80px !important;
                padding-top: 0 !important;
                padding-bottom: 0 !important;
            }

            .datatable-row-wrapper .datatable-body-row .datatable-body-cell {
                height: 80px !important;
                padding-top: 0 !important;
                padding-bottom: 0 !important;
            }
        }

        .datatable-body-row {
            padding: 0;
            background-color: transparent;
            border: none !important;
            border-bottom: 1px solid #f2f5f7 !important;

            @include transition(border 0.2s ease-out);

            @include even() {
                background-color: $table-row-even-bg;
            }

            &:hover {
                background-color: #f2f5f7;
            }
        }

        .datatable-body-cell {
            padding: $table-cell-offset-y math.div($table-cell-offset-x, 2);
            letter-spacing: 1px;
            border-top: none;
            cursor: pointer;
            display: inline-flex;
            align-items: center;

            &.text-overflow-none,
            &.text-overflow-none .datatable-body-cell-label {
                text-overflow: clip;
                overflow: hidden;
            }

            &:last-child {
                padding-right: $table-cell-offset-x;
            }

            &:first-child {
                padding-left: $table-cell-offset-x;
            }

            .table-btn {
                height: 32px;
                min-width: 90px;
                font-size: 12px;
            }

            .table-icon {
                position: relative;
                top: 8px;
                margin-right: 15px;
                width: 24px;
            }

            .btn-table-icon {
                background: transparent;
                position: relative;
                margin-right: 15px;
                cursor: pointer;
                border: none;
                width: 24px;
                padding: 0;
                &:hover {
                    color: #6a798c !important;
                }

                &:disabled {
                    color: #babdc2 !important;
                }
            }

            &.cell-right {
                display: flex;
                justify-content: flex-end;
            }
        }
    }

    .datatable-header {
        .datatable-body-row {
            padding: 0;
            border: none;
        }

        .datatable-header-cell {
            padding: $table-cell-offset-y math.div($table-cell-offset-x, 2)
                $table-cell-offset-y;
            border-bottom: 1px solid #f2f5f7;
            white-space: nowrap;
            font-weight: 300;
            font-size: 12px;

            @extend %heading;

            &:last-child {
                padding-right: $table-cell-offset-x;
            }

            &:first-child {
                padding-left: $table-cell-offset-x;
            }

            &:not(.sortable) {
                .sort-btn {
                    display: none !important;
                }
            }

            .sort-btn {
                display: inline-block;
                margin-left: 5px;

                &:after,
                &:before {
                    display: block;
                    content: '';
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: $sort-height math.div($sort-width, 2)
                        $sort-height math.div($sort-width, 2);
                    border-left-color: transparent !important;
                    border-right-color: transparent !important;
                }

                &:before {
                    border-bottom-color: variables.$darkGrey;
                    border-top: 0;
                    margin-bottom: 2px;
                }

                &.sort-asc {
                    &:before {
                        border-color: variables.$darkGrey;
                    }
                }

                &:after {
                    border-top-color: variables.$darkGrey;
                    border-bottom: 0;
                }

                &.sort-desc {
                    &:after {
                        border-color: variables.$darkGrey;
                    }
                }
            }
        }
    }

    .datatable-footer {
        background-color: transparent;

        .datatable-footer-inner {
            justify-content: flex-end;
        }
    }
}

.table-dashboard {
    margin-bottom: 0;

    td {
        width: 80%;
        padding: 5px 20px;

        &:first-child {
            color: $darkGrey;
        }
    }

    thead {
        td {
            font-weight: 500;
            color: $black;
        }
    }
}

.empty-row {
    text-align: center;
    padding: 10px 0;
    font-size: 18px;

    @include media-breakpoint-up(sm) {
        padding: 60px 0;
        font-size: 30px;
    }

    .empty-leading {
        font-size: 16px;

        img {
            margin: 0 auto 20px;
            display: block;
        }
    }
}

table.detail {
    width: 100%;

    tr {
        display: flex;
        justify-content: space-between;
        th {
            font-weight: 400;
        }
        tr {
            border-bottom: 1px solid $table-border-color;
            &.table-row-no-background {
                background-color: transparent !important;
            }
            &:hover {
                background-color: #f2f5f7;
            }
        }
        td,
        th {
            padding: 15px 10px;
            font-size: 13px;
            color: variables.$ddFontSecondary;

            &.highlighted,
            &.strong {
                color: variables.$ddFontPrimary !important;
            }
        }
    }

    &.without-header {
        border-top: 1px solid $table-border-color;
    }
}
