.absolut-center {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    &-x {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
    }

    &-y {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
    }
}
