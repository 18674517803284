@use 'sass:math';
@use 'styles/variables';

body {
    height: 100%;
    position: relative;
    font-size: variables.$default-font-size;
    color: variables.$ddFontPrimary;
    background-color: variables.$main-content-background;
    min-width: 320px;
}

.mat-drawer-container {
    color: inherit !important;
    background-color: transparent !important;
}

.material-icons {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    display: inline-block;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;

    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    font-feature-settings: 'liga';
}

.fade {
    transition: opacity 0.25s ease;
}

.container {
    max-width: 1200px;
}

.link-inherit {
    cursor: pointer;
    color: inherit;

    &:hover {
        color: variables.$ddCoreYellow;
    }
}

.layout-unauthorized {
    .logo {
        margin: 16px auto;
        text-align: center;

        img {
            vertical-align: top;
            max-width: 100%;
            max-height: 87px;
        }
    }

    .links {
        font-size: 0.9rem;
        margin: 0 0 50px;
    }

    .title {
        margin: 8px 0;
        text-align: center;
    }

    .title-description {
        color: $ddFontSecondary;
    }
}

.main-content {
    padding: 20px 15px;
    flex: 1;
    color: variables.$ddFontPrimary;

    @include media-breakpoint-up(sm) {
        padding: 25px 50px;
    }
}
