@use 'styles/variables';

.modal-form {
    &.modal-form-source {
        padding: 30px 40px;

        @include media-breakpoint-down(md) {
            padding: 20px;
        }

        @include media-breakpoint-down(sm) {
            padding: 10px 20px;
        }

        .label-source {
            position: relative;
            transition: 0.5s all ease;

            .source-radio {
                position: absolute;
                left: -30px;
                top: -2px;
                color: $lightGrey;
                display: none;

                &:before {
                    content: '';
                    position: absolute;
                    top: 3px;
                    left: 3px;
                    background: variables.$ddCoreYellow;
                    width: 18px;
                    height: 18px;
                    z-index: 0;
                    border-radius: 50%;
                }

                i {
                    position: relative;
                    z-index: 10;
                }
            }

            input[type='radio'] {
                &:checked {
                    & + .source-radio {
                        display: block;
                    }

                    & ~ .source-name {
                        font-weight: 600;
                    }
                }
            }
        }
    }

    &.modal-form-style {
        padding: 30px 45px;

        @include media-breakpoint-down(md) {
            padding: 20px;
        }

        @include media-breakpoint-down(sm) {
            padding: 10px 0;
        }
    }
}

.modal-md {
    max-width: 600px;
}

.modal-sm {
    max-width: 500px;
}

.modal-xs {
    max-width: 300px;
}

.chart {
    width: 170px;
    height: 105px;
    background: $darkGrey;
    border-radius: 5px;
    border: 1px solid $darkGrey;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;

    @include media-breakpoint-down(sm) {
        width: 120px;
        height: 80px;
    }

    .chart-icon {
        font-size: 80px;
        color: $lightGrey;

        @include media-breakpoint-down(sm) {
            font-size: 50px;
        }
    }
}

.modal {
    .modal-icon {
        font-size: 36px;
    }
}
