@use 'styles/variables';
// https://www.ag-grid.com/javascript-data-grid/global-style-customisation-colours/
@import '@ag-grid-community/styles/ag-grid.css';
@import '@ag-grid-community/styles/ag-theme-alpine.css';

.ag-theme-alpine {
    --ag-font-family: variables.$lexend;
}

.ag-menu {
    .ag-menu-option {
        &-icon {
            font-size: 16px;
        }
    }
}
.ag-root-wrapper {
    min-height: 200px;
    .ag-root-wrapper-body {
        height: auto !important;
        min-height: auto !important;
        .ag-root {
            height: unset !important;
        }
    }
}
