.mat-tree {
    .mat-nested-tree-node {
        .invisible {
            display: none;
        }
    }

    div[role='group'] > .mat-tree-node {
        padding-left: 40px;
    }

    .mat-nested-tree-node div[role='group'] {
        padding-left: 40px;
    }

    ul,
    li {
        margin-top: 0;
        margin-bottom: 0;
        list-style-type: none;
    }
}
