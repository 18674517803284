@import '@ng-select/ng-select/themes/default.theme.css';

.ng-select {
    outline: none;

    &.form-control {
        padding: 5px 0 5px 5px;

        .ng-select-container {
            border: none;
        }
    }

    &.ng-select-disabled {
        background-color: $ddBackground;
        border: none;
    }

    &.ng-select-multiple {
        .ng-select-container {
            &.ng-has-value {
                .ng-value-container {
                    flex-wrap: nowrap !important;
                    .ng-value {
                        background-color: $ddCoreYellow !important;
                        border-radius: 25px;
                        margin-left: 5px;
                        padding: 0 5px;
                        min-width: 20px;
                        text-align: center;
                    }

                    .ng-placeholder {
                        display: block;
                        position: relative;
                        top: unset;
                    }

                    .ng-value-icon {
                        &.right {
                            border-left-color: $ddBoxes !important;
                        }

                        &.left {
                            border-right-color: $ddBoxes !important;
                        }

                        &:hover {
                            background-color: $ddBoxes !important;
                        }
                    }
                }
            }
        }
    }

    .ng-select-container {
        box-shadow: none !important;

        .ng-value-container .ng-placeholder {
            color: $ddFontSecondary;
        }
    }

    .ng-dropdown-panel {
        border: 1px solid $ddFontSecondary;

        .ng-dropdown-panel-items {
            .ng-option {
                &:not(:last-child) {
                    border-bottom: 1px solid $mediumGrey;
                }

                &.ng-option-marked,
                &.ng-option-selected {
                    background: $ddBoxes;
                }
                &.ng-option-disabled {
                    color: $ddFontSecondary;
                }
                &:hover,
                &.ng-option-marked {
                    white-space: normal;
                }
            }
        }
    }
}
