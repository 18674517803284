.mat-mdc-checkbox {
    &.mat-accent {
        --mdc-checkbox-selected-checkmark-color: white;
    }

    label {
        font-size: 0.85rem;
        color: $ddFontPrimary;
        white-space: normal;
        font-weight: 400;
    }

    .mdc-checkbox__background {
        border-width: 1px;
    }

    .mdc-checkbox,
    .mdc-checkbox:hover,
    .mdc-checkbox:not(:disabled):active {
        .mdc-checkbox__native-control:enabled {
            &:checked,
            &:indeterminate {
                ~ .mdc-checkbox__background {
                    background: $ddCoreBlack !important;
                    border-color: $ddCoreBlack !important;
                }
            }
        }
    }

    .mdc-checkbox {
        .mdc-checkbox__native-control {
            &:enabled:not(:checked):not(:indeterminate):not(
                    [data-indeterminate='true']
                )
                ~ .mdc-checkbox__background {
                border-color: $ddCoreBlack !important;
            }

            &[disabled]:not(:checked):not(:indeterminate):not(
                    [data-indeterminate='true']
                )
                ~ .mdc-checkbox__background {
                border-color: $ddFontDisabled !important;
            }
        }
    }
}
